import React from 'react';

export default class TextFragment extends React.Component {
  render () {
    return (
      <article id="textFragment" onClick={() => getSelection()}>
        <section>
          <p>Je pense qu'il n'y a pas, dans le monde civilisé, de pays oû l'on s'occupe moins de philosophie qu'aux Étas-Unis.</p>
          <p className="hidden">Les Américains n'ont point d'école philosophique qui leur soit propre, et ils s'inquiètent fort peu de toutes celle qui divisent l'Europe; ils en savent à peine les noms.</p>
        </section>
      </article>
    )
  }
}
