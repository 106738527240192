import Popover from '../components/Popover.js';

const animateStepText = (f, t) => {
  let $f = document.querySelector(".step.step-" + f);
  if ($f) $f.classList.remove("show");
  setTimeout(() => {
    let $t = document.querySelector(".step.step-" + t);
    if ($t) $t.classList.add("show");
  }, 200);

  document.querySelector(".step-counter").innerText =  t;
}

const highlightKeys = (keys) => {
  document.querySelectorAll("#keyboardSVG [data-key]").forEach($o => {
    $o.classList.remove("active");
    if (keys.indexOf($o.dataset.key) > -1) {
      setTimeout(() => $o.classList.add("active"), 200);
    }
  })
};

const popovers = [
  () =>
    Popover(
      document.querySelector("#sidebar"), `
      <div class="description">
        This is your Metapanel. You can see all of your highlights, add comments, start conversations with friends and much more. You can open it easily with <b>command + K</b>.
      </div>
      <span class="step-of">1/3</span>
      <div class="right">
        <button class="skip ghost">Skip all</button>
        <span>or</span>
        <button class="next">Next</button>
      </div><div style="clear:both"></div>
      `, "left"
    ),

  () =>
    Popover(
      document.querySelector("#sidebar .notes > div:last-of-type"), `
      <div class="description">
        Highlights can be saved with or without comments. To add a comment to a highlight, simply type something in above!
      </div>
      <span class="step-of">2/3</span>
      <div class="right">
        <button class="skip ghost">Skip all</button>
        <span>or</span>
        <button class="next">Next</button>
      </div><div style="clear:both"></div>
      `,
      "bottom"
    ),

    () =>
      Popover(
        document.querySelector("#sidebar"), `
        <div class="description">
          Finally, Meta lets you see information and discussion about your highlights on relevant websites like Twitter and Hacker News. Keep an eye out, as this section is always magically changing.
        </div>
        <span class="step-of">3/3</span>
        <div class="right">
          <button class="next">All done</button>
        </div><div style="clear:both"></div>
        `,
        "left"
      ),
]

const Tutorial = (isFinished) => {
  const steps = [
    () => {
      animateStepText(null, 1);
    },
    () => {
      animateStepText(1, 2);
      highlightKeys(["return"]);
    },
    () => {
      animateStepText(2, 3);
      setTimeout(() => {
        document.querySelector("#textFragment p.hidden").classList.remove("hidden")
      }, 200);
      highlightKeys(["command", "return"]);
    },
    () => {
      animateStepText(3, 4);
      highlightKeys(["command", "K"]);
    },
    () => {
      let $popover = popovers[0]();
      document.body.appendChild($popover);
      $popover.show();
      $popover.querySelector(".next").focus();

      $popover.querySelector(".next").addEventListener("click", e => {
        let $new_popover = popovers[1]();
        $popover.parentNode.replaceChild($new_popover, $popover);
        $new_popover.show();
        $new_popover.querySelector(".next").focus();

        $new_popover.querySelector(".next").addEventListener("click", e => {
          let $popover = $new_popover;
          $new_popover = popovers[2]();
          $popover.parentNode.replaceChild($new_popover, $popover);
          $new_popover.show();
          $new_popover.querySelector(".next").focus();

          $new_popover.querySelector(".next").addEventListener("click", () => {
            $new_popover.hide();
            isFinished();
          });
        })
      });
    }
  ];
  let activeStep = -1;

  return {
    step: () => {
      activeStep++;
      steps[activeStep]();
    },

    get: () => activeStep,
  };
};

export default Tutorial;

/*
<span className="step step-1">Highlight the text above.</span>
<span className="step step-2">Press <b>return</b> to Save the highlight.</span>
<span className="step step-3">Here's another sentence. Try highlighting this one and adding a comment.</span>
<span className="step step-4">Open the Metapanel to see your saved highlights.</span>
*/
