import { El } from '../utils.js';

const COMMENT_BOX_WIDTH = 420;
const commentBox = (val, { top, left, width }) => El(`
  <div class='comment-box-wrapper' style="top: ${top}px; left: ${Math.max(50 + COMMENT_BOX_WIDTH / 2, left + width / 2)}px;">
    <div class='comment-box'>
      <div class='avatar'></div>
      <div class='comment' contenteditable='true' data-placeholder='Comment'>${val || ""}</div>
      <button>Share</button>
    </div>
  </div>
`);

const createCommentBoxOnInputEvent = (e, id) => {
  if (e.keyCode === 13) {
    store.update(store => store.notes[id] = e.target.innerText.trim());
    closeCommentBoxes();
  }
};

const saveCommentOnButtonClick = (e, id) => {
  store.update(store => store.notes[id] = e.target.parentNode.querySelector(".comment").innerText.trim());
  closeCommentBoxes();
};

const closeCommentBoxes = () => {
  let $wrappers = document.querySelectorAll(".comment-box-wrapper")
  $wrappers.forEach($n => $n.classList.remove("show"));
  setTimeout(() => $wrappers.forEach($n => $n.parentNode.removeChild($n)), 300);
}

const focus = (el) => {
    el.focus();
    if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
};

const createCommentBox = (target) => {
  if (target.closest("#sidebar")) return;

  if (target.closest(".comment-box-wrapper"))
    return;

  if (!target.classList.contains(".range-selection"))
    target = target.closest(".range-selection");

  document.querySelectorAll(".selection--active").forEach($n => $n.classList.remove("selection--active"));

  closeCommentBoxes();

  if (!target) {
    return;
  }

  let matches = document.querySelectorAll(`.selection--${target.dataset.id}`);
  matches.forEach($n => $n.classList.add("selection--active"));

  let position = target.getBoundingClientRect();

  position.y = document.body.scrollTop + position.y;

  let $commentBox = commentBox(store.notes[target.dataset.id], position);
  document.body.appendChild($commentBox);

  let $comment  = $commentBox.querySelector(".comment");
  $comment.addEventListener("keyup", e => createCommentBoxOnInputEvent(e, target.dataset.id));
  $commentBox.querySelector("button").addEventListener("click", e => saveCommentOnButtonClick(e, target.dataset.id));
  focus($comment);


  setTimeout(() => $commentBox.classList.add("show"), 0);
};

export default {
  createCommentBox,
};
