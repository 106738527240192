import rangy from 'rangy';

import modifyHighlightSelection from  '../utils/modifyHighlightSelection.js';
const { createCommentBox } = modifyHighlightSelection;

function getNextNode(node) {
    if (node.firstChild)
      return node.firstChild;

    while (node) {
      if (node.nextSibling)
        return node.nextSibling;
      node = node.parentNode;
    }
}

function getNodesInRange(range) {
  var start = range.startContainer;
  var end = range.endContainer;
  var commonAncestor = range.commonAncestorContainer;
  var nodes = [];
  var node;

  if (start === end) return [start];

  // walk parent nodes from start to common ancestor
  for (node = start.parentNode; node; node = node.parentNode) {
    nodes.push(node);
    if (node == commonAncestor)
      break;
  }
  nodes.reverse();

  // walk children and siblings from start until end is found
  for (node = start; node; node = getNextNode(node)) {
    nodes.push(node);
    if (node == end)
      break;
  }

  return nodes;
}

let SpanFactory = () => {
  let id = Math.random().toString(16).split(/\./)[1];
  let func = ({ innerText }) => {
    let span = document.createElement("span");
    span.classList.add("range-selection");
    span.classList.add("selection--active");
    span.dataset.id = id;
    span.classList.add("selection--" + id);
    span.innerText = innerText;
    return span;
  };

  func.id = id;

  return func;
};

const createHighlightSelection = ({ openCommentBox = false }) => {
  let selection = rangy.getSelection(),
      ranges = selection.getAllRanges();


  // initialize with a range ID.
  let span = SpanFactory();

  // keep this available in case we need it for `openCommentBox` below.
  let first_node;

  ranges.forEach(range => {
    // if you double click to highlight a paragraph, it will do this thing where
    // it makes the range to some element after the paragraph with an offset of
    // 0, which doesn't work w/ my code due to the fact that the endContainer is
    // never a `Text` type, so it'll get killed in the nodes array, therefore
    // it will read it as range [0, 0] and put it at position 0 in the prev text
    // node it finds.
    if (range.endOffset === 0 && range.startOffset === 0 && range.startContainer !== range.endContainer) {
      range.endOffset = Infinity;
    }
    let nodes = getNodesInRange(range).filter(o => o.nodeType !== 1);

    let prefix = document.createTextNode(nodes[0].textContent.slice(0, range.startOffset));
    nodes[0].parentNode.insertBefore(prefix, nodes[0]);

    if (nodes.length === 1) {
      first_node = span({ innerText: nodes[0].textContent.slice(range.startOffset, range.endOffset) });
      nodes[0].parentNode.insertBefore(first_node, nodes[0]);
      nodes[0].textContent = nodes[0].textContent.slice(range.endOffset);
    } else {
      for (let x = 1; x < nodes.length - 1; x++) {
        nodes[x].parentNode.replaceChild(span({ innerText: nodes[x].textContent }), nodes[x]);
      }

      first_node = span({ innerText: nodes[0].textContent.slice(range.startOffset) });
      nodes[0].parentNode.insertBefore(first_node, nodes[0]);
      nodes[0].parentNode.removeChild(nodes[0]);

      let last_node = nodes[nodes.length - 1];

      last_node.parentNode.insertBefore(
        span({ innerText: last_node.textContent.slice(0, range.endOffset) }),
        last_node
      );
      last_node.textContent = last_node.textContent.slice(range.endOffset);
    }

    window.getSelection().empty();
  })

  if (openCommentBox) {
    console.log(first_node)
    createCommentBox(first_node);
  }

  return span.id;
};

export default createHighlightSelection;
