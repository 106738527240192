import React from 'react';

import Keyboard from '../components/Keyboard.js';
import TextFragment from '../components/TextFragment.js';
import Sidebar from '../components/Sidebar.js';

import createHighlightSelection from  '../utils/createHighlightSelection.js';
import modifyHighlightSelection from  '../utils/modifyHighlightSelection.js';

import Tutorial from '../components/Tutorial.js';

import Alert from '../components/Alert.js';

const { createCommentBox } = modifyHighlightSelection;

const getLetters = e => {
  let arr = [];
  arr.push(e.key.toUpperCase());

  if (e.shiftKey) arr.push("shift");
  if (e.keyCode === 13) arr.push("return");
  if (e.metaKey) arr.push("command");

  return arr;
}

const registerOnKeyDownEventListener = cb => {
  document.body.addEventListener("keydown", e => {
    cb.call(this, e);
  });
}

const registerOnKeyUpEventListener = cb => {
  document.body.addEventListener("keyup", e => {
    cb.call(this, e);
  })
};

const registerOnClickEventListener = cb => {
  document.body.addEventListener("click", e => {
    cb.call(this, e);
  });
};

window.store = {
  notes: {},
};

store.update = cb => {
  const res = cb(store);
  if (store.callbacks) store.callbacks.forEach(o => o(store));
  return res;
};

store.callback = cb => {
  if (!store.callbacks) store.callbacks = [];
  store.callbacks.push(cb);
};

export default class Demo extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      alert: null,
      letters: [],
    };
  }

  componentDidMount () {
    let sidebarHasBeenShown = false;
    registerOnKeyDownEventListener(e => {
      // this.setState({ letters: getLetters(e) });
      if (e.keyCode === 13) {
        let id = createHighlightSelection({ openCommentBox: e.metaKey });
        this.setState({ alert: (
          <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11" cy="11" r="11" fill="#71EC60"/>
              <path d="M7 12L9.47843 14.5L15 8.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Highlight Saved!</span>
          </>
        ) })
      }

      if (e.key === "k" && e.metaKey) {
        if (!sidebarHasBeenShown && this.tutorial.get() === 3) {
          sidebarHasBeenShown = true;
          store.demo = true;
          setTimeout(() => this.tutorial.step(), 300);
        }
        store.update(store => store.sidebar = !store.sidebar);
      }
    });

    registerOnKeyUpEventListener(e => {
      setTimeout(() => this.setState({ letters: [] }), 100);

      let selection = window.getSelection();

      if (
        this.tutorial.get() === 1 &&
        e.keyCode === 13 &&
        selection.anchorNode === null
      ) {
        this.tutorial.step();
      }
    });

    registerOnClickEventListener(e => {
      createCommentBox(e.target);

      if (this.tutorial.get() === 0) {
        let selection = window.getSelection();
        if (selection.anchorOffset !== selection.extentOffset && selection.anchorNode.parentNode.closest("#textFragment p")) {
          this.tutorial.step();
        }
      }
    });

    store.callback(_ => this.tutorial.get() === 2 && this.tutorial.step())

    this.tutorial = Tutorial(() => {
      store.demo = false;
      store.update(store => store.sidebar = false);
      setTimeout(() => this.setState({ isFinished: true  }), 300);
    });

    this.tutorial.step();
  }

  render () {
    return (
      <>
        <div className={"demo" + (this.state.isFinished ? " hide" : "")}>
          <div className="demo-header">
            <h1>Welcome to <span>Meta</span></h1>
            <h2>Meta is the best way to highlight and discuss the web.</h2>
          </div>
          <div className="center-wrapper">
            <TextFragment />
            <Keyboard letters={this.state.letters} />
          </div>
          <div className="demo-footer">
            <h1>Step <span className="step-counter">1</span></h1>
            <h2>
              <span className="step step-1">Highlight the text above.</span>
              <span className="step step-2">Press <b>return</b> to Save the highlight.</span>
              <span className="step step-3">Here's another sentence. Try highlighting this one and adding a comment.<br /><br />Press <b>command + return</b> to make a comment.</span>
              <span className="step step-4">Open the Metapanel to see your saved highlights.<br /><br />Press <b>command + K</b> open.</span>
            </h2>
          </div>
        </div>
        <div id="screenSizeTooSmall"><span>Your screen size is too small to see this demo. Please view on a laptop or desktop.</span></div>
        <div id="finishedScreen" className={this.state.isFinished && "show"}><span>Thanks for trying out <b>Meta</b>!</span></div>
        <Alert alert={this.state.alert} />
        <Sidebar />
      </>
    )
  }
}
