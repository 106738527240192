import { El } from '../utils.js';

const popover = (content, direction) => El(`
  <div class="popover-wrapper popover--${direction}">
    <div class="popover">
    ${ content }
    </div>
  </div>
`);

const Popover = (parent, content, direction) => {
  let $popover = popover(content, direction);

  let top, left,
      rect = parent.getBoundingClientRect();

  if (direction === "left" || direction === "right") {
    top = (rect.top + rect.height / 2) + document.body.scrollTop;
  }

  if (direction === "left") {
    left = rect.left - 10;
  }

  if (direction === "right") {
    left = rect.right + 10;
  }

  if (direction === "top" || direction === "bottom") {
    left = (rect.left + rect.right) / 2;
  }

  if (direction === "top") {
    top = rect.top + document.body.scrollTop;
  }

  if (direction === "bottom") {
    top = rect.top + rect.height + document.body.scrollTop + 10;
  }

  $popover.style.top = top + "px";
  $popover.style.left = left + "px";

  $popover.show = () => setTimeout(() => $popover.classList.add("show"), 50);
  $popover.hide = () => $popover.classList.remove("show");
  return $popover;
};

export default Popover;
