import React from 'react';

export default class Alert extends React.Component {
  constructor () {
    super();

    this.state = {
      show: false,
    };

    this.updateSignature = null;
  }

  componentDidUpdate (prevProps) {
    if (this.props.alert !== prevProps.alert && !this.state.show) {
      let updateSignature = Math.random();
      this.updateSignature = updateSignature;

      this.setState({ show: true });
      setTimeout(() => {
        console.log("close", this.updateSignature, updateSignature)
        if (this.updateSignature === updateSignature) {
          this.setState({ show: false });
        }
      }, 3000);
    } else if (!this.props.alert && this.state.show) {
      this.setState({ show: false });
      this.activeTimeout = setTimeout(() => this.setState({ alert: null }), 200);
    }
  }

  render () {
    return (
      <div className={"alert-box" + (this.state.show ? " show" : "")}>
        <div className="alert">{ this.props.alert }</div>
      </div>
    )
  }
}
