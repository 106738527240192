import React from 'react';

import Popover from './Popover.js';
import { Form, Input, Button } from './Form.js';

const Note = ({ fragment, comment }) => {
  return (
    <div className="note">
      <section dangerouslySetInnerHTML={{ __html: fragment }}></section>
      <div className="note-comment">
        <div className="avatar" />
        <div className="note-comment-block">
          <div className="author">Brock Whittaker</div>
          <div className="comment">{ comment }</div>
        </div>
      </div>
    </div>
  )
};

const getFragmentByKey = (key) => {
  let parents = Array.prototype.slice.call(
    document.querySelectorAll(`.selection--${key}`)
  )
    .map(o => o.closest("p"))
    .filter(o => !o.closest("#sidebar"));

  parents = parents
    .filter(
      (o, i) => parents.slice(i + 1).indexOf(o) === -1
    )
    .map(o => o.cloneNode(true));

  parents.forEach(o => {
    o.querySelectorAll(`span:not(.selection--${key})`).forEach(o => {
      let text = document.createTextNode(o.innerText);
      o.parentNode.replaceChild(text, o);
    })
  });

  parents.forEach(o => o.querySelectorAll(".selection--active").forEach(o => o.classList.remove("selection--active")));

  return parents.reduce((a, b) => a + b.outerHTML, "");
};

export default class Sidebar extends React.Component {
  constructor (props) {
    super(props);

    store.callback(store => {
      this.setState({ notes: Object.keys(store.notes).map(o => ({
        fragment: getFragmentByKey(o),
        comment: store.notes[o],
      })) })
    });

    this.state = {
      notes: [],
    };
  }

  closeSidebar () {
    if (store.demo) return;
    store.update(store =>  store.sidebar = false)
  }

  login () {

  }

  render () {
    return (
      <>
      <div className={"sidebar-backdrop" + (store.sidebar ? " show" : "")} onClick={() => this.closeSidebar()} />
      <div ref={ref => this.node = ref} id="sidebar" className={store.sidebar && "show"}>
        <header>
          <svg onClick={() => this.closeSidebar()} className="close-arrow" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5L1 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h2>Meta</h2>
          <div className="avatar" />
        </header>
        <div className="notes">
          { this.state.notes.map(({ fragment, comment }) => <Note fragment={fragment} comment={comment} />) }
        </div>
      </div>
      </>
    )
  }
}
