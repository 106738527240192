import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Demo from './views/Demo.js';

class App extends React.Component {
  render () {
    return (
      <Demo />
    )
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
