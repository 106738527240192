import React from 'react';

const handleUpdate = (e, payload) => {
  if (!payload) payload = {};
  const { presetValue } = payload;

  let path = [];
  let pointer = e.target;
  while (pointer.tagName !== "FORM") {
    if (pointer.dataset.prefix) {
      path.push(pointer.dataset.prefix);
    }
    pointer = pointer.parentNode;
  }

  let value = (presetValue || e.target.value || e.target.innerText);

  e.update = {
    value: typeof value === "string" ? value.trim() : value,
    key: payload.key || e.target.dataset.name.trim(),
    path,
  };
};

export const validateForm = ($form) => {
  let errors = [];
  $form.querySelectorAll("input, select, textarea").forEach($o => {
    if ($o.dataset.required && $o.value.trim().length === 0) {
      errors.push({ name: $o.dataset.name, label: $o.dataset.label });
    }
  });

  $form.querySelectorAll("div.input").forEach($o => {
    if ($o.dataset.required && $o.innerText.trim().length === 0) {
      errors.push({ name: $o.dataset.name, label: $o.dataset.label });
    }
  });

  return errors;
};

export class Input extends React.Component {
  render () {
    let type = this.props.type === "password" ? "password" : "text";

    return (
      <label className="input-box">
        <input
          {...this.props}
          placeholder=""
          label=""
          name=""
          data-name={this.props.name}
          data-label={this.props.label}
          type={type}
          onBlur={e => handleUpdate(e)}
          autoComplete={type === "password" ? "new-password" : "no"}
          data-required={'required' in this.props}
          required
        />
        <span className="label" data-placeholder={this.props.placeholder || this.props.label}><div className="label-content">{ this.props.label }</div></span>
      </label>
    )
  }
}

export class DivInput extends React.Component {
  render () {
    let type = this.props.type === "password" ? "password" : "text";

    return (
      <label className="input-box input-box--div">
        <div
          {...this.props}
          className={"input" + (this.props.className ? " " + this.props.className : "")}
          data-name={this.props.name}
          data-label={this.props.label}
          type={type}
          onBlur={e => handleUpdate(e)}
          contentEditable={true}
          data-required={'required' in this.props}
          required
        />
        <span className="label">{ this.props.label }</span>
      </label>
    )
  }
}

export class Textarea extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }
  updateCount (e) {
    this.setState({ currentLength: e.target.innerText.trim().length });
  }

  render () {
    let type = this.props.type === "password" ? "password" : "text";

    return (
      <label className="input-box input-box--textarea">
        <div
          {...this.props}
          className={"input" + (this.props.className ? " " + this.props.className : "")}
          data-name={this.props.name}
          data-label={this.props.label}
          data-placeholder={this.props.placeholder}
          type={type}
          onBlur={e => handleUpdate(e)}
          onInput={e => this.updateCount(e)}
          contentEditable={true}
          data-required={'required' in this.props}
          required
        />
        <span className="label">{ this.props.label }</span>
        { this.props.maxLength && <div className="max-length">{ this.state.currentLength || 0 }/{ this.props.maxLength }</div> }
      </label>
    )
  }
}

export class Button extends React.Component {
  render () {
    const { children } = this.props;

    return (
      <button type="submit" {...this.props}>{children}</button>
    )
  }
}

export class TagField extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tags: this.props.defaultValue || [],
    };
  }

  componentDidUpdate (prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ tags: this.props.defaultValue });
    }
  }

  handleUpdate (e) {
    const presetValue = e.target.innerText.trim();
    const isEmpty = presetValue.length === 0;

    if (e.keyCode === 13 && !isEmpty) {
      let { tags } = this.state;
      if (tags.indexOf(presetValue) === -1) {
        tags.push(presetValue);
      }
      handleUpdate(e, {
        presetValue: tags,
      });
      this.setState({ tags });
      e.target.innerText = "";
      e.preventDefault();
    }

    if ((e.keyCode === 8 || e.keyCode === 37) && isEmpty && this.$input.previousElementSibling) {
      this.$input.previousElementSibling.focus();
    }
  }

  handleTagActions (tag, e) {
    const { keyCode, target } = e;

    if (keyCode === 8) {
      const { previousElementSibling, nextElementSibling } = target;

      const tags = this.state.tags.filter(o => o !== tag);
      handleUpdate(e, {
        presetValue: tags,
        key: this.props.name,
      });
      this.setState({ tags }, () => {
        if (previousElementSibling) {
          previousElementSibling.focus();
        } else if (nextElementSibling) {
          nextElementSibling.focus();
        } else {
          this.$input.focus();
        }
      });
      return;
    }

    if (keyCode === 37) {
      if (target.previousElementSibling) target.previousElementSibling.focus();
      return;
    }

    if (keyCode === 39) {
      if (target.nextElementSibling) target.nextElementSibling.focus();
      return;
    }
  }

  render () {
    return (
      <label className="input-box input-box--tag-field">
        <div className="tags" ref={node => this.$tags = node}>
          { this.state.tags.map(tag => <div className="tag" tabIndex={1} onKeyDown={e => this.handleTagActions(tag, e)}>{ tag }</div>) }
          <div className="input" data-name={this.props.name} contentEditable={true} ref={node => this.$input = node} {...this.props} onKeyDown={e => this.handleUpdate(e)} data-placeholder={this.props.placeholder} />
          </div>
        <span className="label">{ this.props.label }</span>
      </label>
    )
  }
}

let getCoordsFromTouch = e => {
  if (e.touches) {
    return {
      screenX: e.touches[0].screenX,
      screenY: e.touches[0].screenY,
    };
  } else if (e.pageX || e.pageY) {
    return {
      screenX: e.pageX,
      screenY: e.pageY,
    };
  }
};

export class Range extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
  }

  touchStart (e) {
    let { screenX, screenY } = getCoordsFromTouch(e);
    this.last = { screenX: screenX };
    this.prevOffset = this.prevOffset || 0;

    this.maxWidth = this.thumb.parentNode.getBoundingClientRect().width;
    this.thumbWidth = this.thumb.getBoundingClientRect().width;
  }

  move (e) {
    if (!this.last) return;

    let { screenX, screenY } = getCoordsFromTouch(e);

    let offset = Math.min(this.maxWidth - this.thumbWidth, Math.max(0, (screenX - this.last.screenX) + this.prevOffset));
    this.thumb.style.transform = `translate(${offset}px, -50%)`;
    this.prevOffset = offset;
    this.last.screenX = screenX;

    this.fillTrack.style.width = offset + "px";

    let percentage = this.prevOffset / (this.maxWidth - this.thumbWidth);
    let currentValue = Math.round((this.props.max - this.props.min) * percentage + this.props.min);

    this.rangeValue.innerText = currentValue;
    this.state.currentValue = currentValue;
    this.props.didUpdate(currentValue);
    e.stopPropagation();
  }

  touchEnd (e) {
    delete this.last;
  }

  updateDefaultValue () {
    if (typeof this.props.defaultValue === "number") {
      this.maxWidth = this.thumb.parentNode.getBoundingClientRect().width;
      this.thumbWidth = this.thumb.getBoundingClientRect().width;

      let percentage = (this.props.defaultValue - this.props.min) / (this.props.max - this.props.min);
      let offset = percentage * (this.maxWidth - this.thumbWidth);
      this.prevOffset = offset;
      this.thumb.style.transform = `translate(${offset}px, -50%)`;

      this.rangeValue.innerText = this.props.defaultValue;
      return;
    }

    this.rangeValue.innerText = this.props.min;
    this.thumb.style.transform = `translate(0px, -50%)`;
    this.prevOffset = 0;
  }

  componentDidMount () {
    this.updateDefaultValue();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.updateDefaultValue();
    }
  }

  render () {
    let labelWithSign = val => val > 0 ? "+" + val : val;

    return (
      <div className="input-box range">
        { this.props.labels }
        <div className="range--action action--left">{ labelWithSign(this.props.min) }</div>
        <div className='range--wrapper'>
          <div className="range--track">
            <div ref={ref => this.fillTrack = ref} className="range--fill-track" />
            <div
              ref={ref => this.thumb = ref}
              className="range--thumb"
              onTouchStart={e => this.touchStart(e)}
              onTouchMove={e => this.move(e)}
              onTouchEnd={e => this.touchEnd(e)}
              onMouseDown={e => this.touchStart(e)}
              onMouseMove={e => this.move(e)}
              onMouseUp={e => this.touchEnd(e)}
            ><div ref={ref => this.rangeValue = ref} className='range--value'></div></div>
          </div>
        </div>
        <div className="range--action action--right">{ labelWithSign(this.props.max) }</div>
        <span className="label">{ this.props.label }</span>
      </div>
    )
  }
}

const getStage = (idx, activeIdx) => {
  if (idx < activeIdx) return `animate-up`;
  else if (idx === activeIdx) return ``;
  else if (idx > activeIdx) return `animate-down`;

  return console.warn(`Error. The stage is not supported:\n  idx: ${idx}\n  activeIdx: ${idx}`)
}

export class Part extends React.Component {
  render () {
    return (
      <div className={"part " + getStage(this.props.idx, this.props.stage)} data-idx={this.props.idx}>
        { this.props.children }
      </div>
    )
  }
}

export class ButtonBorder extends React.Component {
  render () {
    return (
      <div className="button--border button--border--small">
        <button {...this.props}>{ this.props.children }</button>
        { new Array(this.props.depth || 1).fill(0).map((_, idx) => (
          <div className="backdrop" style={{ transform: `translate(${idx * 2}px, ${idx * 2}px)`, zIndex: 4 - idx }}></div>
        )) }
      </div>
    );
  }
}

export class Form extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      form: this.props.data || {},
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ form: this.props.data });
    }
  }

  handleUpdate (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
    }

    if (e.update) {
      let newForm = { ...this.state.form };
      let pointer = newForm;
      e.update.path.reverse().forEach(key => {
        if (!pointer[key]) {
          pointer[key] = {};
        } else {
          pointer[key] = { ...pointer[key] };
        }
        pointer = pointer[key];
      });

      pointer[e.update.key] = e.update.value;

      let column = e.update.path[e.update.path.length - 1] || e.update.key;
      if (this.props.onUpdate) this.props.onUpdate(column, newForm[column], newForm);

      this.setState({ form: newForm });
      delete e.update;
    }
  }

  onSubmit (e) {
    e.preventDefault();
    this.props.onSubmit(this.state.form);
  }

  render () {
    const className = "form " + (this.props.className || "");
    return (
      // the `onKeyDown` is to support tags, and since we check for `e.update`
      // existing, this should in effect be inert for other types of actions.
      <form {...this.props} autoComplete={"false"} className={className} onBlur={e => this.handleUpdate(e)} onKeyDown={e => this.handleUpdate(e)} onSubmit={e => this.onSubmit(e)}>
        { this.props.children }
      </form>
    )
  }
}
